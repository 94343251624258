export const PROPS_TO_PICK = [
  'distance',
  'startTime',
  'activity.distance',
  'activity.average_heartrate',
  'avgPace',
  'activity.pace',
  'activity.moving_time',
  'pulse',
];
