import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './ComparsionChart.styles.scss';
import Chart from 'react-apexcharts';
import { GroupBy, groupTrainingPlansBy } from './helpers/GroupActivities.helper';
import { Button, Dropdown } from 'ncoded-component-library';
import classNames from 'classnames';
import api from 'api';
import { TrainingPlan } from 'models/TrainingPlan';
import { useChartData } from './hooks/UseChartData.hook';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import pick from 'lodash/pick';
import { Activity } from 'api/users/activities';
import { normalizeDateForChart } from 'helpers/isoDateStringRegexReplace.util';
import PageContentContainer from 'components/PageContentContainer';
import { BsChevronDown } from 'react-icons/bs';

type ComparsionChartProps = {
  className?: string;
};

const groupByActions = ['day', 'week', 'month', 'year'];

const ComparsionChart: React.FC<ComparsionChartProps> = (props) => {
  const { className } = props;
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>();
  const [groupBy, setGroupBy] = useState<GroupBy>('day');
  const [startDate, setStartDate] = useState<Date>(new Date('2021-01-01T21:56:40.309Z'));
  const [endDate, setEndDate] = useState<Date>(new Date());

  const classes = classNames(className, 'comparsion-chart');

  const groupedPlansReduced = useMemo(() => {
    if (!trainingPlans || !trainingPlans.length || !groupBy) return trainingPlans;

    const grouped = groupTrainingPlansBy(trainingPlans, groupBy);
    const groupedExtracted = Object.values(grouped).map((plans) => plans.map((tp) => pick(tp, ['distance', 'startTime', 'activity.distance'])));
    const reducedGroups = groupedExtracted.map((group) => {
      const first = group[0];
      const last = group[group.length - 1];

      const reduced = group.reduce(
        (acc, tp) => ({
          ...acc,
          activity: { distance: acc.activity.distance + tp.activity.distance } as unknown as Activity,
          distance: acc.distance + tp.distance,
        }),
        { distance: 0, activity: { distance: 0 }, startTime: moment(first.startTime).utc().toISOString() },
      );

      if (group.length > 1) {
        reduced.startTime = moment(last.startTime).utc().toISOString() + moment(first.startTime).utc().toISOString();
      }
      reduced.startTime = normalizeDateForChart(reduced.startTime);
      return reduced;
    });

    return reducedGroups.reverse();
  }, [trainingPlans, groupBy]);

  const { options, series } = useChartData({
    trainingPlans: groupedPlansReduced as unknown as Partial<
      TrainingPlan & {
        activity: Partial<Activity>;
      }
    >[],
  });

  useEffect(() => {
    (async function () {
      const plans = await api.users.getTrainingPlans({
        onlyAttached: true,
        from: moment(startDate).startOf('day').toISOString(),
        to: moment(endDate).endOf('day').toISOString(),
      });
      setTrainingPlans(plans);
    })();
  }, [startDate, endDate]);

  const handleOnGroupChange = useCallback((groupType: GroupBy) => {
    setGroupBy(groupType);
  }, []);

  const handleOnDateChange = useCallback((range) => {
    const [start, end] = range;
    setStartDate(start as Date);
    setEndDate(end as Date);
  }, []);

  return (
    <div className={classes}>
      <PageContentContainer>
        <div className="ptm-page-menu">
          <Dropdown
            className="ptm-page-with-list"
            trigger={
              <Button icon={BsChevronDown} iconPosition="right">
                Group By
              </Button>
            }
          >
            <ul className="ptm-page-list">
              {groupByActions.map((actionLabel) => (
                <li key={actionLabel}>
                  <button onClick={handleOnGroupChange.bind(this, actionLabel as GroupBy)}>{actionLabel}</button>
                </li>
              ))}
            </ul>
          </Dropdown>
          <Dropdown
            trigger={
              <Button icon={BsChevronDown} iconPosition="right">
                Chose date
              </Button>
            }
          >
            <DatePicker selected={startDate} onChange={handleOnDateChange} startDate={startDate} endDate={endDate} selectsRange inline />
          </Dropdown>
        </div>
        {options && series && <Chart options={options} series={series} type="bar" width="1000" />}
      </PageContentContainer>
    </div>
  );
};

export default ComparsionChart;
