import Axios from 'axios';
import credentialsService from 'services/credentialsService';
import env from 'env';
import { toast } from 'react-toastify';

const httpClient = Axios.create({
  baseURL: `${env.SERVER_ENDPOINT}/api`,
});

httpClient.interceptors.request.use(
  function (config) {
    const { token } = credentialsService;
    if (token && config.method !== 'OPTIONS') {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

httpClient.interceptors.response.use(null, (error) => {
  if (error.response && [401, 403].includes(error.response.status)) {
    credentialsService.removeAuthBody();
    window.location.reload();
    toast('Session expired.', { type: 'info', position: 'top-center' });
  }
  return Promise.reject(error);
});

export default httpClient;
