import classNames from 'classnames';
import React from 'react';
import './CardContainer.styles.scss';

type CardContainerProps = {
  className?: string;
};

const CardContainer: React.FC<CardContainerProps> = (props) => {
  const { children, className } = props;

  const classes = classNames(className, 'card-container');
 
  return <div className={classes}>{children}</div>;
};

export default CardContainer;
