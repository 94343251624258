import { AuthBody } from 'models/AuthBody';
import API from '../httpClient';

const getStravaLoginLink = async () => {
  try {
    const { data } = await API.get<{ url: string }>('/oauth/strava');
    return data.url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getGoogleLoginLink = async (userId: string) => {
  try {
    const { data } = await API.get<{ url: string }>(`/oauth/google?userId=${userId}`);
    return data.url;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const getToken = async (query: { code: string; state: string; scope: string }) => {
  try {
    const { code, state, scope } = query;
    const { data } = await API.get<AuthBody>(`/oauth/strava/callback?scope=${scope}&state=${state}&code=${code}`);
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export default {
  getStravaLoginLink,
  getGoogleLoginLink,
  getToken,
};
