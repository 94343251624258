import classNames from 'classnames';
import React from 'react'
import './NavigationBottomMenu.styles.scss';

type NavigationBottomMenuProps = {
  className?:string;
};

const NavigationBottomMenu: React.FC<NavigationBottomMenuProps> = (props) => {
  const { children,className } = props;


  const classes=classNames("navigation-bottom-menu",className)

  return <div className={classes}>{children}</div>
};

export default NavigationBottomMenu;