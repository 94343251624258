import { GridColDef } from '@mui/x-data-grid';
import { secondsToDuration } from 'helpers/durationFormatter.util';
import { humanizeDistance, normalizePace } from 'helpers/normalizers.util';
import moment from 'moment';

const getColumns = (): GridColDef[] => [
  {
    field: 'name',
    headerName: 'name',
    type: 'string',
    flex: 1,
  },
  {
    field: 'activeDuration',
    headerName: 'Active Duration',
    type: 'number',
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
  },
  {
    field: 'pace',
    headerName: 'Pace (min/km)',
    type: 'number',
    editable: true,
    valueFormatter: ({ value }) => normalizePace(value),
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'startTime',
    headerName: 'Starting Time',
    valueGetter: (params) => moment(params.value.toString() as unknown as string).format('HH:mm:ss DD.MM.yyyy'),
    type: 'date',
    flex: 1,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'number',
    flex: 1,
    align: 'center',
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (value) => humanizeDistance(+value.value),
  },
  {
    field: 'totalDuration',
    headerName: 'Total Training Duration',
    type: 'string',
    flex: 1,
    editable: true,
    disableColumnMenu: true,
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
    sortable: false,
  },
  {
    field: 'distance',
    headerName: 'Distance',
    type: 'number',
    flex: 1,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (value) => humanizeDistance(+value.value),
  },
  {
    field: 'pulse',
    headerName: 'Pulse (bpm)',
    type: 'number',
    flex: 1,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'userId',
    headerName: 'User Id',
    type: 'number',
    hide: true,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'id',
    headerName: 'Training Id',
    type: 'string',
    hide: true,
    flex: 1,
    disableColumnMenu: true,
    sortable: false,
  },
];

export { getColumns };
