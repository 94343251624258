import React from 'react';

type IconProps = React.SVGProps<SVGSVGElement>;

export default (props: IconProps) => (
  <svg width="48" height="48" viewBox="0 0 16 16" fill="transparent" {...props}>
    <path d="M0 0h16v16H0z" />
    <g fill="currentColor" fillRule="evenodd">
      <path d="M6.9 8.8l2.5 4.5 2.4-4.5h-1.5l-.9 1.7-1-1.7z" />
      <path d="M7.2 2.5l3.1 6.3H4zm0 3.8l1.2 2.5H5.9z" />
    </g>
  </svg>
);
