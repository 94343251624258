import React from 'react';
import Header from '../Header';
import Navigation from '../Navigation';
import classNames from 'classnames';
import { NavigationProps } from '../Navigation/Navigation.component';
import { HeaderProps } from '../Header/Header.component';
import './PageWrapper.styles.scss';

type PageWrapperProps = {
  rootClassName?: string;
  header?: HeaderProps;
  navigation: NavigationProps;
  className?: string;
};

const PageWrapper: React.FC<PageWrapperProps> = (props) => {
  const { children, rootClassName, navigation, header, className } = props;

  const classes = classNames('navigation-wrapper', rootClassName);

  const childrenClasses = classNames(
    'navigation-wrapper__content-container',
    className,
  );

  return (
    <div className={classes}>
      <Header {...header} />
      <div className="navigation-wrapper__row">
        <Navigation {...navigation} />
        <div className={childrenClasses}> {children}</div>
      </div>
    </div>
  );
};

export default PageWrapper;
