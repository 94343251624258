import classNames from 'classnames';
import React from 'react'
import './ToggleButton.styles.scss';

type DefaultInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type ToggleButtonProps = {
 
}&DefaultInputProps;


const ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const { children , checked, onClick, ...rest} = props;

  const circleClasses=classNames("circle",{"circle--checked":checked});

  return (
  <label   className="toggle-button"  >
    <input  hidden type="checkbox" checked={checked} onClick={onClick} {...rest} />
    <span className={circleClasses} />
    {children}
  </label>
  )
};

export default ToggleButton;