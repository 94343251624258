export const humanizeDistance = (value: number) => `${value >= 1000 ? `${Number(+value / 1000).toFixed(2)} km` : `${Number(+value).toFixed(2)} m`}`;

/**
 * value is in minutes
 */
export const normalizePace = (value: number) => {
  const [wholePart, decimalPart] = value.toFixed(2).split('.');
  const seconds = decimalPart ? ((Number(decimalPart) / 100) * 60).toFixed(2) : '00';
  const sec = Math.ceil(Number(seconds)).toString();
  return `${wholePart}:${sec.length < 2 ? `0${sec}` : sec}`;
};

export const normalizePulse = (value: number) => {
  if (!Number.isNaN(value) && value === 0) {
    return 'N/A';
  }
  return Number(value).toFixed(2);
};
