import { GridColDef } from '@mui/x-data-grid';
import { secondsToDuration } from 'helpers/durationFormatter.util';
import { humanizeDistance, normalizePace, normalizePulse } from 'helpers/normalizers.util';

export const getColumns = (props?: {}): GridColDef[] => [
  {
    field: 'groupMembersCount',
    headerName: 'Count',
    type: 'number',
    disableColumnMenu: true,
    sortable: false,
    maxWidth: 60,
  },
  {
    field: 'startTime',
    headerName: 'Time period',
    type: 'string',
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'activeDuration',
    headerName: 'Average Duration',
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
    type: 'number',
    width: 100,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'totalActiveDuration',
    headerName: 'Total Duration',
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
    type: 'number',
    width: 100,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'activityDistance',
    headerName: 'Total Distance',
    type: 'number',
    width: 160,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (value) => humanizeDistance(+value.value),
    flex: 1,
  },
  {
    field: 'avgActivityDistance',
    headerName: 'Avg Activity Distance',
    type: 'number',
    width: 160,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (params) => humanizeDistance(+params.value),
    valueParser: (params) => params.value,
    flex: 1,
  },
  {
    field: 'avgPace',
    headerName: 'Avg Pace (mm:ss/km)',
    type: 'number',
    width: 250,
    valueFormatter: (params) => normalizePace(params.value),
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'avgPulse',
    headerName: 'Avg Pulse (bpm)',
    type: 'number',
    width: 250,
    valueFormatter: (params) => normalizePulse(params.value),
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
];
