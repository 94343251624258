import React, { useCallback } from 'react';
import { DashboardProvider } from '../models';
import DashboardContext from './Dashboard.context';

type DashboardProviderProps = {};

const DashboardProviderComponent: React.FC<DashboardProviderProps> = ({
  children,
}) => {
  const toggleTheme = useCallback(() => {
    document.body.classList.toggle('dark');
  }, []);

  const providerValue: DashboardProvider = {
    toggleTheme,
  };

  return (
    <DashboardContext.Provider value={providerValue}>
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProviderComponent;
