import DisplayActivity from 'components/DisplayActivity';
import useCallbackRef from 'hooks/useCallbackRef';
import { TrainingPlan } from 'models/TrainingPlan';
import { Modal } from 'ncoded-component-library';
import { ModalRef } from 'ncoded-component-library/build/components/organisms/Modal/Modal.component';

export type ActivityNameRenderCellProps = {
  trainingPlan: TrainingPlan;
};

const ActivityNameRenderCell: React.FC<ActivityNameRenderCellProps> = (
  props,
) => {
    const { trainingPlan } = props;
    const [modal, modalRef] = useCallbackRef<ModalRef>();

  return (
    trainingPlan.activityName && (
      <>
        <label
          onClick={() => {
            modal?.open();
          }}
        >
          {trainingPlan.activityName}
        </label>
        <Modal ref={modalRef}>
          <DisplayActivity activityId={trainingPlan.activityId} />
        </Modal>
      </>
    )
  );
};

export default ActivityNameRenderCell;
