import React, { useEffect, useState } from 'react';
import './Login.styles.scss';
import CardContainer from 'components/CardContainer';
import api from 'api';
import useQueryParams from 'hooks/useQueryParams';
import credentialsService from 'services/credentialsService';
import { useHistory } from 'react-router';
import StravaLogoIcon from 'icons/StavaLogo.icon';

type LoginProps = {};

const Login: React.FC<LoginProps> = (props) => {
  const [stravaLink, setStravaLink] = useState<string>();

  const { params } = useQueryParams<{ code: string; scope: string; state: string }>();
  const history = useHistory();

  useEffect(() => {
    (async () => {
      try {
        const [stravaLoginLink] = await Promise.all([api.auth.getStravaLoginLink()]);
        setStravaLink(stravaLoginLink);
      } catch (e) {
        console.error(e);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        let creds = null;
        if (params.code) {
          creds = await api.auth.getToken(params); // my backend strava/callback
        }
        if (creds) {
          credentialsService.saveAuthBody(creds);
          history.push('/');
        }
      } catch (e) {
        console.error(e);
      }
    })();
  }, [params, history]);

  return (
    <div className="login">
      <CardContainer className="login__card">
        <h1 style={{ color: 'black' }}>PTM</h1>
        <StravaLogoIcon />
        <a href={stravaLink}>Log in via Strava account</a>
      </CardContainer>
    </div>
  );
};

export default Login;
