import ConnectGrid from "components/ConnectGrid";
import useCallbackRef from "hooks/useCallbackRef";
import { TrainingPlan } from "models/TrainingPlan";
import { Button, Modal } from "ncoded-component-library";
import { ModalRef } from "ncoded-component-library/build/components/organisms/Modal/Modal.component";
import { SyntheticEvent } from "react";

export type ActivityRenderCellProps = {
    onOpen?: (trainingPlan: TrainingPlan, e?: SyntheticEvent) => void;
    onActivityDetach?: (trainingPlan: TrainingPlan, e?: SyntheticEvent) => void;
    updatePlans: (val: TrainingPlan) => void;
    trainingPlan: TrainingPlan;
  };

  const ActivityRenderCell: React.FC<ActivityRenderCellProps> = (props) => {
    const { trainingPlan, updatePlans } = props;
    const [modal, modalRef] = useCallbackRef<ModalRef>();
  
    return trainingPlan.activityId < 0 ? (
      <>
        <Button
          onClick={() => {
            props?.onOpen.bind(null, trainingPlan);
            modal?.open();
          }}
          icon={<div className="icon--plus" />}
          variant="icon"
        />
        <Modal ref={modalRef} className="connect-grid-modal">
          <ConnectGrid
            trainingPlan={trainingPlan}
            onTrainingPlanChange={updatePlans}
          />
        </Modal>
      </>
    ) : (
      <Button
        onClick={props?.onActivityDetach.bind(null, trainingPlan)}
        ripple
        icon={<div className="icon--minus" />}
        variant="icon"
      />
    );
  };
  
  export default ActivityRenderCell;