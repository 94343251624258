import StravaLogoIcon from '../../icons/StavaLogo.icon';
import './Header.styles.scss';

export type HeaderProps = {};

const Header: React.FC<HeaderProps> = (props) => {

  return (
    <header className="header">
      <StravaLogoIcon />
    </header>
  );
};

export default Header;
