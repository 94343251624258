import { List, ListItemButton, ListItemText } from '@mui/material';
import { GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import { toast } from 'react-toastify';

type CustomToolbarProps = {
  className?: string;
};

const CustomToolbar: React.FC<CustomToolbarProps> = (props) => {
  const { className } = props;
  const classes = classNames(className, 'custom-toolbar');
  const gridApiRef = useGridApiContext();

  return (
    <GridToolbarContainer className={classes}>
      <Button variant={'outline'}>
        <List>
          <ListItemButton
            onClick={(e) => {
              e.preventDefault();
              navigator.clipboard.writeText(gridApiRef.current.getDataAsCsv());
              toast('Coppied to clipboard!', { type: 'success', position: 'top-center' });
            }}
          >
            <ListItemText primary="Copy as CSV" />
          </ListItemButton>
        </List>
      </Button>
      <Button variant={'outline'}>
        <List>
          <ListItemButton
            onClick={(e) => {
              gridApiRef.current.exportDataAsCsv();
            }}
          >
            <ListItemText primary="Download as CSV" />
          </ListItemButton>
        </List>
      </Button>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
