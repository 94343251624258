import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { Button, Dropdown } from 'ncoded-component-library';
import { GroupBy, groupTrainingPlansBy } from 'pages/ComparsionChart/helpers/GroupActivities.helper';
import classNames from 'classnames';
import moment from 'moment';
import PageContentContainer from 'components/PageContentContainer';
import { getColumns } from './helpers/getColumns';
import { TrainingPlan } from 'models/TrainingPlan';
import { DataGrid } from '@mui/x-data-grid';
import api from 'api';
import CustomToolbar from './components/CustomToolbar.component';
import pick from 'lodash/pick';
import { Activity } from 'api/users/activities';
import { normalizeDateForChart } from 'helpers/isoDateStringRegexReplace.util';
import { PROPS_TO_PICK } from './utils';
import './Statistics.styles.scss';
import { BsChevronDown } from 'react-icons/bs';
import { LinearProgress } from '@mui/material';

type StatisticsProps = {
  className?: string;
};

const groupByActions = ['day', 'week', 'month', 'year'];

const Statistics: React.FC<StatisticsProps> = (props) => {
  const { className } = props;
  const classes = classNames(className, 'statistics-page');
  const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>([]);
  const [groupBy, setGroupBy] = useState<GroupBy>('day');
  const [startDate, setStartDate] = useState<Date>(new Date('2021-01-01T21:56:40.309Z'));
  const [endDate, setEndDate] = useState<Date>(new Date());
  const [isTableLoading, setIsTableLoading] = useState(false);

  const handleOnGroupChange = useCallback((groupType: GroupBy) => {
    setGroupBy(groupType);
  }, []);

  const handleOnDateChange = useCallback((range) => {
    const [start, end] = range;

    setStartDate(
      moment(start as Date).isValid()
        ? moment(start as Date)
            .startOf('day')
            .toDate()
        : null,
    );
    setEndDate(
      moment(end as Date).isValid()
        ? moment(end as Date)
            .endOf('day')
            .toDate()
        : null,
    );
  }, []);

  useEffect(() => {
    (async () => {
      setIsTableLoading(true);
      const plans = await api.users.getTrainingPlans({
        onlyAttached: true,
        from: moment(startDate).startOf('day').toISOString(),
        to: moment(endDate).endOf('day').toISOString(),
      });
      setTrainingPlans(plans);
      setIsTableLoading(false);
    })();
  }, [endDate, startDate]);

  const groupedPlansReduced = useMemo(() => {
    if (!trainingPlans || !trainingPlans.length || !groupBy) return trainingPlans;

    const grouped = groupTrainingPlansBy(trainingPlans, groupBy);

    const groupedExtracted = Object.values(grouped).map((plans) => plans.map((tp) => pick(tp, PROPS_TO_PICK)));

    const reducedGroups = groupedExtracted.map((group, index) => {
      const first = group[0];
      const last = group[group.length - 1];

      const reduced = group.reduce<any>(
        (acc, tp) => ({
          ...acc,
          activity: {
            distance: acc.activity.distance + tp.activity.distance,
            pace: acc.activity.pace + tp.activity.pace,
            moving_time: acc.activity.moving_time + tp.activity.moving_time,
            average_heartrate: acc.activity.average_heartrate + (tp.activity.average_heartrate || 0),
          } as Activity,
          distance: acc.distance + tp.distance,
          activityDistance: acc.activity.distance + tp.activity.distance,
          avgPace: 0,
          activeDuration: 0,
        }),
        {
          distance: 0,
          avgPace: 0,
          activity: { distance: 0, pace: 0, moving_time: 0, average_heartrate: 0 },
          startTime: moment(first.startTime).utc().toISOString(),
        },
      );
      reduced.avgActivityDistance = reduced.activityDistance / group.length;
      reduced.avgPace = reduced.activity.pace / group.length;
      reduced.activeDuration = reduced.activity.moving_time / group.length;
      reduced.totalActiveDuration = reduced.activity.moving_time;
      reduced.avgPulse = reduced.activity.average_heartrate / group.length;
      reduced.groupMembersCount = group.length;
      if (group.length > 1) {
        reduced.startTime = moment(last.startTime).utc().toISOString() + moment(first.startTime).utc().toISOString();
      }
      reduced.startTime = normalizeDateForChart(reduced.startTime);
      return reduced;
    });

    return reducedGroups.reverse();
  }, [trainingPlans, groupBy]);

  return (
    <div className={classes}>
      <PageContentContainer>
        <div className="ptm-page-menu">
          <Dropdown
            trigger={
              <Button icon={BsChevronDown} iconPosition="right">
                Group By
              </Button>
            }
          >
            <ul className="ptm-page-list">
              {groupByActions.map((actionLabel) => (
                <li key={actionLabel}>
                  <button onClick={handleOnGroupChange.bind(this, actionLabel as GroupBy)}>{actionLabel}</button>
                </li>
              ))}
            </ul>
          </Dropdown>
          <Dropdown
            trigger={
              <Button icon={BsChevronDown} iconPosition="right">
                Chose date
              </Button>
            }
          >
            <DatePicker selected={startDate} onChange={handleOnDateChange} startDate={startDate} endDate={endDate} selectsRange inline />
          </Dropdown>
        </div>
        <DataGrid
          className="statistics-page__data-grid"
          rows={groupedPlansReduced.map((p, index) => ({
            ...p,
            id: index,
          }))}
          loading={isTableLoading}
          columns={getColumns()}
          checkboxSelection
          disableColumnMenu={false}
          componentsProps={{
            loadingOverlay: {
              color: 'inherit',
            },
          }}
          components={{ Toolbar: CustomToolbar, LoadingOverlay: LinearProgress }}
        />
      </PageContentContainer>
    </div>
  );
};

export default Statistics;
