import API from '../httpClient';
import CredentialsService from '../../services/credentialsService';
import type { TrainingPlan } from '../../models/TrainingPlan';
import { User } from 'models/User';

export type PaginatedResponse<T> = {
  items: T[];
  totalPages: number;
  currentPage: number;
  totalItems: number;
};
export type TrainingPlanReqBody = {
  startTime: string;
  activeDuration: string;
  totalDuration: string;
  distance: string;
  avgPace: string;
  pulse: string;
  cadence: string;
  elevation: string;
};

const createTrainingPlan = async (body: TrainingPlanReqBody) => {
  const { data } = await API.post<TrainingPlan>('/users/training-plans', body, {
    headers: {
      Authorization: CredentialsService.token,
      'Content-Type': 'application/json',
    },
  });
  return data;
};

type GetTrainingPlansOpts = {
  onlyAttached?: boolean;
  from?: string;
  to?: string;
  sortDir?: number;
};

const getTrainingPlans = async ({
  onlyAttached = false,
  from = new Date(0).toISOString(),
  to = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString(),
  sortDir = 1,
}: GetTrainingPlansOpts = {}) => {
  const { data } = await API.get<TrainingPlan[]>(`/users/training-plans?onlyAttached=${onlyAttached}&from=${from}&to=${to}&sortDir=${sortDir}`, {
    headers: { Authorization: CredentialsService.token },
  });
  return data;
};

const getTrainingPlan = async () => {
  const { data } = await API.get<TrainingPlan>(`/users/training-plans`, {
    headers: { Authorization: CredentialsService.token },
  });
  return data;
};
type DeleteTrainingPlanResponse = { deletedCount: number };

const deleteTrainingPlan = async (id: string) => {
  const { data } = await API.delete<DeleteTrainingPlanResponse>(`/users/training-plans/${id}`, {
    headers: { Authorization: CredentialsService.token },
  });
  return data.deletedCount;
};
const updateTrainingPlan = async (id: string, updateBody: Partial<TrainingPlan>) => {
  const { data } = await API.patch<TrainingPlan>(`/users/training-plans/${id}`, updateBody, { headers: { Authorization: CredentialsService.token } });
  return data;
};
const importTrainingPlan = async (id: string) => {
  const { data } = await API.post<TrainingPlan[]>('/users/training-plans/import', {
    sheetId: id,
  });
  return data;
};

export interface UserModel {
  _id: string;
  stravaId: number;
  firstName: string;
  lastName: string;
  profile: string;
  profileMedium: string;
  auth: Auth[];
  createdAt: string;
  updatedAt: string;
  __v: number;
  id: string;
}

export interface Auth {
  _id: string;
  type: string;
  expiresAt?: number;
  accessToken: string;
  refreshToken: string;
}

const getCurrentUser = async () => {
  const { data: user } = await API.get<User>('/users/current');
  return user;
};

export default {
  createTrainingPlan,
  getTrainingPlan,
  getTrainingPlans,
  updateTrainingPlan,
  deleteTrainingPlan,
  importTrainingPlan,
  getCurrentUser,
};
