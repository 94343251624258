import { Activity } from 'api/users/activities';
import moment from 'moment';
import { secondsToDuration } from './durationFormatter.util';
import { normalizePace } from './normalizers.util';

export type DisplayActivityType = Activity & {
  startTime: string;
  activeDuration: number;
  totalDuration: number;
  userId: number;
  pulse: number;
  elevation: number;
  pace: number;
};

export const humanizeActivity = (activity: DisplayActivityType) => ({
  startTime: moment(activity.startTime).format('HH:mm:ss DD.MM.yyyy'),
  activeDuration: secondsToDuration(activity.activeDuration),
  totalDuration: secondsToDuration(activity.totalDuration),
  elevation: `${activity.elevation} m`,
  pace: `${normalizePace(activity.pace)} min/km`,
  distance: `${activity.distance >= 1000 ? `${Number(activity.distance / 1000).toFixed(2)} km` : `${activity.distance} m`}`,
  pulse: `${typeof activity.pulse === 'string' ? activity.pulse : `${activity.pulse} bpm`} `,
});
