import { GridColDef, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';
import { humanizeDistance, normalizePace } from 'helpers/normalizers.util';
import moment from 'moment';
import { secondsToDuration } from '../../../../../helpers/durationFormatter.util';

export const getColumns = (props: {
  activityRenderCellComponent?: (params: GridRenderCellParams) => React.ReactNode;
  activityNameRenderCellComponent?: (params: GridRenderCellParams) => React.ReactNode;
  startTimeEditCellComponent?: (params: GridRenderEditCellParams) => React.ReactNode;
}): GridColDef[] => [
  {
    field: 'Activity',
    type: 'actions',
    disableColumnMenu: true,
    sortable: false,
    renderCell: props.activityRenderCellComponent,
    width: 80,
  },
  {
    field: 'activityName',
    headerName: 'Activity Name',
    type: 'string',
    width: 100,
    editable: false,
    disableColumnMenu: true,
    sortable: false,
    renderCell: props.activityNameRenderCellComponent,
    flex: 1,
  },
  {
    field: 'activeDuration',
    headerName: 'Active Duration (h:m:s)',
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
    type: 'number',
    width: 100,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'avgPace',
    headerName: 'Pace (min/km)',
    type: 'number',
    width: 250,
    editable: true,
    valueFormatter: (params) => normalizePace(params.value),
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'startTime',
    headerName: 'Starting Time',
    type: 'dateTime',
    width: 180,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueGetter: (params) => params.value,
    valueFormatter: (params) => params.value && moment(params.value.toString() as unknown as string).format('DD.MM.yyyy. HH:mm:ss'),
  },
  {
    field: 'elevation',
    headerName: 'Elevation',
    type: 'number',
    width: 150,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (value) => humanizeDistance(+value.value),
    flex: 1,
  },
  // {
  //   field: 'totalDuration',
  //   headerName: 'Total Training Duration (h:m:s)',
  //   type: 'string',
  //   width: 300,
  //   editable: true,
  //   disableColumnMenu: true,
  //   sortable: false,
  //   valueParser: (value, params) => {
  //     const [hour, minutes, seconds] = value.split(':');
  //     return +hour * 3600 + +minutes * 60 + +seconds;
  //   },
  //   valueGetter: ({ value }) => {
  //     console.log('🚀 -> file: getColumns.tsx -> line 87 -> value', value, secondsToDuration(Number(value)));

  //     return secondsToDuration(Number(value));
  //   },
  //   valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
  //   flex: 1,
  // },
  {
    field: 'totalDuration',
    headerName: 'Total Training Duration (h:m:s)',
    type: 'string',
    width: 300,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: ({ value }) => secondsToDuration(value.valueOf() as number),
    flex: 1,
  },
  {
    field: 'distance',
    headerName: 'Distance',
    type: 'number',
    width: 160,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
    valueFormatter: (value) => humanizeDistance(+value.value),
    flex: 1,
  },
  {
    field: 'pulse',
    headerName: 'Pulse (bpm)',
    type: 'number',
    width: 180,
    editable: true,
    disableColumnMenu: true,
    sortable: false,
  },
  {
    field: 'userId',
    headerName: 'User Id',
    type: 'number',
    hide: true,
    width: 220,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
  {
    field: 'id',
    headerName: 'Training Id',
    type: 'string',
    hide: true,
    width: 220,
    disableColumnMenu: true,
    sortable: false,
    flex: 1,
  },
];
