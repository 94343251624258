import moment from 'moment';
import { Activity } from 'api/users/activities';
import { TrainingPlan } from 'models/TrainingPlan';

export type GroupBy = 'day' | 'week' | 'month' | 'year';

export const groupActivitiesBy = (
  activities: Partial<Activity>[],
  by: GroupBy,
) => {
  return groupElementsByDateProp(activities, by, 'start_date');
};

export const groupTrainingPlansBy = (
  trainingPlans: TrainingPlan[],
  by: GroupBy,
) => {
  return groupElementsByDateProp<TrainingPlan>(trainingPlans, by, 'startTime');
};

type FilteredKeysByValue<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

const isInTheSameWeek = (comparee: string, compare: string) =>
  moment(comparee).year() === moment(compare).year() &&
  moment(comparee).week() === moment(compare).week();

const isSameDay = (comparee: string, compare: string) =>
  isInTheSameWeek(comparee, compare) &&
  moment(comparee).day() === moment(compare).day();

const isInTheSameMonth = (comparee: string, compare: string) =>
  moment(comparee).year() === moment(compare).year() &&
  moment(comparee).month() === moment(compare).month();

const isSameYear = (comparee: string, compare: string) =>
  moment(comparee).year() === moment(compare).year();

/**
 * Group array elements, NOTE: array must be sorted in descendent order by the date prop
 * @param array
 * @param by
 * @param datePropPath
 */
export const groupElementsByDateProp = <T = Record<string, unknown>>(
  inputArray: T[],
  by: GroupBy,
  datePropPath: FilteredKeysByValue<T, string>,
) => {
  const array = JSON.parse(JSON.stringify(inputArray));
  array.reverse();

  const byMap = {
    day: isSameDay,
    week: isInTheSameWeek,
    month: isInTheSameMonth,
    year: isSameYear,
  };

  /**
   * Returns true if 'comparee' and 'compare' are in the same time interval group.
   */
  const isInLast = (comparee: string, compare: string) =>
    byMap[by](comparee, compare);

  let compareIndex = 0;
  let compareeIndex = compareIndex + 1;
  let count = 0;
  const grouped: Record<string, T[]> = { 0: [array[compareIndex]] };

  while (compareeIndex < array.length) {
    const latestInTheInterval = array[compareIndex][datePropPath];
    const currentToCompare = array[compareeIndex][datePropPath];

    if (
      isInLast(
        currentToCompare as unknown as string,
        latestInTheInterval as unknown as string,
      )
    ) {
      if (!grouped[count]) grouped[count] = [];
      grouped[count].push(array[compareeIndex]);
    } else {
      if (!grouped[++count]) grouped[count] = [];
      grouped[count].push(array[compareeIndex]);
      compareIndex = compareeIndex;
    }
    compareeIndex++;
  }

  return grouped;
};
