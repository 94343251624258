import * as React from 'react';

type IconProps = {
  name: string;
};

const ImportIcon: React.FC<IconProps> = (props) => {
  return <div />;
};

export default ImportIcon;
