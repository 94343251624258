import React, { useState, useEffect, useMemo, useCallback } from 'react';
import classNames from 'classnames';
import './ConnectGrid.styles.scss';
import api from 'api';
import { DataGrid } from '@mui/x-data-grid';
import { Modal } from 'ncoded-component-library';
import { getColumns } from './utils';
import { Activity } from 'api/users/activities';
import { TrainingPlan } from 'models/TrainingPlan';
import useCallbackRef from 'hooks/useCallbackRef';
import { Button } from 'ncoded-component-library';
import { toast } from 'react-toastify';

type ConnectGridProps = {
  className?: string;
  trainingPlan?: TrainingPlan;
  onTrainingPlanChange: (val: TrainingPlan) => void;
};

type ModalRef = {
  open?: () => void;
  close?: () => void;
  current?: unknown;
};

export const ConnectGrid = (props: ConnectGridProps) => {
  const { className, trainingPlan, onTrainingPlanChange } = props;
  const [confirmationModal, confirmationModalRef] = useCallbackRef<ModalRef>();
  const [data, setData] = useState([]);
  const [selectionModel, setSelectionModel] = useState(undefined);
  const [row, setRow] = useState(null);

  const classes = classNames('connect-grid', className);

  useEffect(() => {
    const fetchActivities = async () => {
      const items = await api.activities.getActivities();
      setData(items);
    };

    fetchActivities();
    const activityIdAttachedToTrainingPlan = trainingPlan.activityId;
    const selected = activityIdAttachedToTrainingPlan > -1 ? [activityIdAttachedToTrainingPlan] : [];
    setSelectionModel(selected);
  }, [trainingPlan]);

  const attachActivityToTrainingPlan = useCallback(
    async (trainingPlanId: string, activityId: number, activityName: string) => {
      const updated = await api.users.updateTrainingPlan(trainingPlanId, {
        activityId,
        activityName,
      });
      onTrainingPlanChange(updated);

      toast.success('Success!');
      setSelectionModel(updated.activityId > 0 ? [updated.activityId] : []);
    },
    [onTrainingPlanChange],
  );

  const confirmationModalSubmit = useCallback(
    async (isConfirmed: boolean, selectedActivity: Activity) => {
      if (isConfirmed) {
        attachActivityToTrainingPlan(trainingPlan._id, selectedActivity.id, selectedActivity.name);
      }
      confirmationModal?.close();
    },
    [attachActivityToTrainingPlan, confirmationModal, trainingPlan],
  );

  const renderConfirmationModal = useMemo(
    () => (
      <Modal ref={confirmationModalRef} title="Are you sure that you want to connect the training plan with the activity?" className="modal">
        <div className="modal__buttons">
          <Button variant="outline" value="no" onClick={() => confirmationModalSubmit(false, row)}>
            No
          </Button>
          <Button value="yes" onClick={() => confirmationModalSubmit(true, row)}>
            Yes
          </Button>
        </div>
      </Modal>
    ),
    [confirmationModalRef, confirmationModalSubmit, row],
  );

  return (
    <div className={classes}>
      <h2>Select an activity that you want to attach to the training plan.</h2>
      <DataGrid
        className="connect-grid__data-grid"
        onSelectionModelChange={(newSelection) => {
          setSelectionModel(newSelection);
        }}
        selectionModel={selectionModel}
        onRowClick={(p, e) => {
          setRow(p.row);
          confirmationModal?.open();
        }}
        columns={getColumns()}
        rows={data}
      />
      {renderConfirmationModal}
    </div>
  );
};

export default ConnectGrid;
