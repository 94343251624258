import React, { useMemo, useState, useContext } from 'react';
import classNames from 'classnames';
import { NavLink, useHistory } from 'react-router-dom';
import DashboardContext from 'router/subrouters/Dashboard/providers/Dashboard.context';
import { BsSun, BsMoon } from 'react-icons/bs';

import './Navigation.styles.scss';
import ToggleButton from 'components/ToggleButton';
import NavigationBottomMenu from './components/NavigationBottomMenu';
import { Button } from 'ncoded-component-library';
import credentialsService from 'services/credentialsService';

export type NavigationOption = {
  label: string;
  to: string;
  icon?: React.ReactNode;
  className?: string;
};

export type NavigationProps = {
  className?: string;
  options: NavigationOption[];
  isOpen?: boolean;
};

const Navigation: React.FC<NavigationProps> = (props) => {
  const { className, options, isOpen } = props;
  const history = useHistory();
  const { toggleTheme } = useContext(DashboardContext);

  const classes = classNames(
    'ptm-navigation',
    { 'ptm-navigation--open': isOpen },
    className,
  );

  const [checked, setChecked] = useState(false);

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked((s) => !s);
    toggleTheme();
  };

  const renderOptions = useMemo(
    () =>
      options.map(({ label, to, className: optionClassName, icon }) => (
        <li key={to + label}>
          <NavLink
            to={to}
            className={optionClassName}
            activeClassName="activeLink"
            isActive={(match) => match?.isExact}
          >
            {icon}
            <span>{label}</span>
          </NavLink>
        </li>
      )),
    [options],
  );

  return (
    <nav className={classes}>
      <ul>{renderOptions}</ul>
      <NavigationBottomMenu>
        <Button
          onClick={() => {
            credentialsService.removeAuthBody();
            history.push('/login');
          }}
        >
          Logout
        </Button>
        <ToggleButton checked={checked} onChange={handleClick}>
          <BsSun className={checked && 'svg-checked'} />
          <BsMoon className={!checked && 'svg-checked'} />
        </ToggleButton>
      </NavigationBottomMenu>
    </nav>
  );
};

export default Navigation;
