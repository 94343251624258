import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import DataGrid from 'components/DataGrid';
import api from 'api';
import { TrainingPlan } from 'models/TrainingPlan';
import { GridEditRowsModel } from '@mui/x-data-grid';
import { useHistory } from 'react-router';
import { getColumns } from './getColumns';
import './TrainingPlan.styles.scss';
import PageContentContainer from '../../../../../components/PageContentContainer';
import { useCallback } from 'react';
import ActivityRenderCell from './components/ActivityRenderCell.component';
import ActivityNameRenderCell from './components/ActivityNameRenderCell.component';
import credentialsService from 'services/credentialsService';

type TrainingPlanProps = {
  className?: string;
};

const transformCellUpdateToPatchBody = (a: GridEditRowsModel) => {
  if (!a || Object.keys(a).length === 0) return {};
  return Object.entries(a).map(([k, v]) => ({ [k]: v.value as any }))[0];
};

const TrainingPlanPage: React.FC<TrainingPlanProps> = (props) => {
  const { className } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);

  const classes = classNames(className, 'training-plan-page');

  const [trainingPlans, setTrainingPlans] = useState<TrainingPlan[]>([]);
  const [selectedTrainingPlanIds, setSelectedTrainingPlanIds] = useState<string[]>([]);
  const [googleLink, setGoogleLink] = useState<string>(null);

  const history = useHistory();

  const getUser = useCallback(async () => {
    try {
      const user = await api.users.getCurrentUser();
      credentialsService.updateUser(user);
      return user;
    } catch (err) {
      console.error(err);
      return null;
    }
  }, []);

  // useEffect(() => {
  //   (async () => {
  //     const user = await getUser();
  //     if (credentialsService.googleIntegration) {
  //       console.log('integrated with google');
  //       return;
  //     }
  //     console.log('not integrated with google');
  //     console.log(user);
  //   })();
  // }, [getUser]);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const [plans, googleLoginLink] = await Promise.all([
        api.users.getTrainingPlans(),
        api.auth.getGoogleLoginLink(credentialsService.user._id),
        getUser(),
      ]);
      setTrainingPlans(plans);
      setGoogleLink(googleLoginLink);
      setIsLoading(false);
    })();
  }, [getUser]);

  const onSelectionModelChange = (selected: string[]) => {
    setSelectedTrainingPlanIds(selected);
  };
  const onImportButtonClicked = useCallback(() => {
    if (!credentialsService.googleIntegration && googleLink) {
      const newTab = window.open(googleLink, '_blank');
      newTab.focus();
      return false;
    }
    return true;
  }, [googleLink]);

  const onImportSubmit = async (values: Record<string, unknown>) => {
    setIsTableLoading(true);
    const sheetId = values['sheetId'] as string;
    const importedPlans = await api.users.importTrainingPlan(sheetId);
    // todo: add loading while import is not finished
    setTrainingPlans([...trainingPlans, ...importedPlans]);
    setIsTableLoading(false);
  };

  const deleteTrainingPlan = async () => {
    if (!selectedTrainingPlanIds.length) {
      return;
    }

    setIsTableLoading(true);
    await Promise.all(selectedTrainingPlanIds.map((id) => api.users.deleteTrainingPlan(id)));
    setTrainingPlans(trainingPlans.filter((tp) => !selectedTrainingPlanIds.includes(tp._id)));
    setIsTableLoading(false);
  };

  const detachActivity = async (trainingPlan: TrainingPlan) => {
    const { _id } = trainingPlan;
    await api.users.updateTrainingPlan(_id, {
      activityId: -1,
      activityName: '',
    });
    setTrainingPlans((old) =>
      old.map((tp) => {
        if (tp._id === _id) {
          return { ...tp, activityId: -1, activityName: '' };
        }
        return tp;
      }),
    );
  };

  const print = async (a: GridEditRowsModel = {}) => {
    const updateInfo = Object.entries(a).map(([id, patchBody]) => ({
      id,
      patchBody,
    }))[0];
    if (!updateInfo) return;
    const { id, patchBody } = updateInfo;
    await api.users.updateTrainingPlan(id, transformCellUpdateToPatchBody(patchBody));
  };
  const updatePlans = useCallback(
    (trainingPlan: TrainingPlan) =>
      setTrainingPlans((old: TrainingPlan[]) => old.map((oldPlan) => (oldPlan._id === trainingPlan._id ? trainingPlan : oldPlan))),
    [],
  );

  return (
    <div className={classes}>
      <PageContentContainer>
        <DataGrid
          loading={isTableLoading || isLoading}
          className="training-plan-page__data-grid"
          columns={getColumns({
            activityRenderCellComponent: (params) => (
              <ActivityRenderCell
                trainingPlan={params.row as TrainingPlan}
                updatePlans={updatePlans}
                onActivityDetach={detachActivity}
                onOpen={(trainingPlan) => {
                  history.push('/connect', { trainingPlan });
                }}
              />
            ),
            activityNameRenderCellComponent: (params) => <ActivityNameRenderCell trainingPlan={params.row as TrainingPlan} />,
            // startTimeEditCellComponent: (params)=>(<ReactDatePicker selected={new Date(params.value.toString())} value={params.value.toString()} showTimeInput onChange={(value)=> params.api.setEditCellValue({ id:params['id'], field: params['field'], value: value })}/>)
          })}
          onSelectionModelChange={onSelectionModelChange}
          onEditCellChange={print}
          onImportSubmit={onImportSubmit}
          onAddClicked={() => console.log('dodaj trening')}
          onDeleteClicked={deleteTrainingPlan}
          onImportButtonClicked={onImportButtonClicked}
          selectedTrainingPlanIds={!!selectedTrainingPlanIds.length}
          rows={trainingPlans.map((p, index) => ({
            ...p,
            id: p?._id || index,
          }))}
          setTrainingPlans={setTrainingPlans}
        />
      </PageContentContainer>
    </div>
  );
};

export default TrainingPlanPage;
