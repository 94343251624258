import api from 'api';
import { Activity } from 'api/users/activities';
import classNames from 'classnames';
import { humanizeActivity } from 'helpers/humanizeActivity.util';
import { Loader } from 'ncoded-component-library';
import React, { useMemo, useState } from 'react';
import { useEffect } from 'react';
import './DisplayActivity.styles.scss';

type DisplayActivityProps = {
  activityId: number;
  className?: string;
};

type DisplayActivityType = Activity & {
  activeDuration: number;
  startTime: string;
  elevation: number;
  totalDuration: number;
  pulse: number;
  userId: number;
  pace: number;
};

const LABEL_VALUE = {
  activeDuration: 'Active duration',
  startTime: 'Start time',
  elevation: 'Elavation',
  totalDuration: 'Total duration',
  pulse: 'Pulse',
  pace: 'Pace',
  distance: 'Distance',
};

const DisplayActivity: React.FC<DisplayActivityProps> = ({ className, activityId }) => {
  const [activity, setActivity] = useState<DisplayActivityType>();
  const [isLoading, setIsLoading] = useState(false);

  const classes = classNames('display-activity', className);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const activity = await api.activities.getActivity(String(activityId));

        setActivity(activity);
      } catch (e) {
        console.error(e);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [activityId]);

  const renderActivity = useMemo(() => {
    if (!activity || isLoading) return <Loader className="display-activity__loader" />;
    const displayProps = humanizeActivity(activity);

    return (
      <>
        <h2>{`Activity - ${activity.name}`}</h2>
        <ul key={activity.id}>
          {Object.entries(displayProps).map(([key, value]) => (
            <li key={`${key}`}>
              <span>{LABEL_VALUE[key as keyof typeof LABEL_VALUE]}</span>
              <span>{value}</span>
            </li>
          ))}
        </ul>
      </>
    );
  }, [activity, isLoading]);

  return <div className={classes}>{renderActivity}</div>;
};

export default DisplayActivity;
