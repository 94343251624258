import API from '../../httpClient';

export interface Athlete {
  id: number;
  resource_state: number;
}

export interface Map {
  id: string;
  summary_polyline: string;
  resource_state: number;
}

export interface Activity {
  resource_state: number;
  athlete: Athlete;
  name: string;
  distance: number;
  moving_time: number;
  elapsed_time: number;
  total_elevation_gain: number;
  type: string;
  id: number;
  external_id: string;
  upload_id: number;
  start_date: string;
  start_date_local: string;
  timezone: string;
  utc_offset: number;
  start_latlng: number[];
  end_latlng: number[];
  location_city?: any;
  location_state?: any;
  location_country?: any;
  start_latitude: number;
  start_longitude: number;
  achievement_count: number;
  kudos_count: number;
  comment_count: number;
  athlete_count: number;
  photo_count: number;
  map: Map;
  trainer: boolean;
  commute: boolean;
  manual: boolean;
  private: boolean;
  visibility: string;
  flagged: boolean;
  gear_id?: any;
  from_accepted_tag: boolean;
  upload_id_str: string;
  average_speed: number;
  max_speed: number;
  average_cadence: number;
  has_heartrate: boolean;
  average_heartrate?: number;
  max_heartrate?: number;
  heartrate_opt_out: boolean;
  display_hide_heartrate_option: boolean;
  elev_high: number;
  elev_low: number;
  pr_count: number;
  pace: number;
  total_photo_count: number;
  has_kudoed: boolean;
}

const calculatePace = (
  movingTimeSeconds: number,
  totalDistanceMeters: number,
) =>
  Number(
    Number(movingTimeSeconds / 60 / (totalDistanceMeters / 1000)).toFixed(2),
  );

const transformPropertiesToMatchTrainingPlans = (activity: Activity) => {
  const data: any = { ...activity };
  data.activeDuration = activity.moving_time;
  data.startTime = activity.start_date;
  data.elevation = activity.total_elevation_gain;
  data.totalDuration = activity.elapsed_time;
  data.pulse = activity.average_heartrate || 'N/A';
  data.userId = activity.athlete.id;
  data.pace = calculatePace(activity.moving_time, activity.distance);

  return data as Activity & {
    activeDuration: number;
    startTime: string;
    elevation: number;
    totalDuration: number;
    pulse: number;
    userId: number;
    pace: number;
  };
};

const getActivities = async (attachedOnly = true) => {
  const { data } = await API.get<Activity[]>(
    `/users/activities${!attachedOnly ? '?attachedOnly=false' : ''}`,
  );
  return data.map((x) => transformPropertiesToMatchTrainingPlans(x));
};

const getActivity = async (id: string) => {
  const { data } = await API.get<Activity>(`/users/activities/${id}`);
  return transformPropertiesToMatchTrainingPlans(data);
};

export default {
  getActivities,
  getActivity,
};
