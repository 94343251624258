import Login from 'pages/Login';
import { EnhancedRouteProps } from './routes/Enhanced/Enhanced.route';
import redirect from 'router/modules/redirect';
import Dashboard from './subrouters/Dashboard';
import PageContentContainer from 'components/PageContentContainer';

const NotFound = () => {
  return (
    <PageContentContainer>
      <h1>NotFound</h1>
    </PageContentContainer>
  );
};

export default [
  {
    path: '/login',
    authorized: false,
    onlyPublic: true,
    component: Login,
  },
  {
    path: '/',
    component: Dashboard,
    exact: false,
    authorized: true,
  },
  // {
  //   path: '/',
  //   component: redirect('/'),
  // },
  {
    path: '/not-found',
    component: () => <NotFound />,
  },
  {
    path: '*',
    component: redirect('/not-found', true),
  },
] as Array<EnhancedRouteProps>;
