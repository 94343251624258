import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import Routes from '../../components/Routes';
import PageWrapper from '../../../components/PageWrapper/PageWrapper.component';
import routes from './routes';
import DashboardProviderComponent from './providers';

const DashboardRouter: React.FC<RouteComponentProps> = () => {
  return (
    <DashboardProviderComponent>
      <PageWrapper
        navigation={{
          options: [
            {
              label: 'View Training Plans',
              to: '/trainings',
            },
            {
              label: 'Comparsion Chart',
              to: '/comparsion-chart',
            },
            {
              label: 'Statistics',
              to: '/statistics',
            },
          ],
        }}
      >
        <Routes routes={routes} />
      </PageWrapper>
    </DashboardProviderComponent>
  );
};

export default DashboardRouter;
