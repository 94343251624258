const TIME_REGEX = /^[0-2][0-3]:[0-5][\d]:[0-5][\d]$/;

const minValue = (min: number) => (value: number) => isNaN(value) || value >= min ? undefined : `Should be greater than ${min}`;

const required = (value: any) => (value ? undefined || NaN || '' || null : 'Required');

const time = (value: string) => {
  if (!value) return undefined;

  if (!value.match(TIME_REGEX)) {
    return 'Not valid time format.';
  }
};

const composeValidators =
  (...validators: any) =>
  (value: any) =>
    validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export default {
  required,
  minValue,
  composeValidators,
  time,
};
