import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Button, Input, Modal } from 'ncoded-component-library';
import ExportIcon from './components/export.icon';
import { Form, Field } from 'react-final-form';
import { DataGrid, GridColumns, GridEditRowsModel, GridRowsProp, GridToolbarContainer, GridToolbarExport } from '@mui/x-data-grid';
import useCallbackRef from 'hooks/useCallbackRef';
import TrainingPlanForm from 'components/TrainingPlanForm';
import { LinearProgress } from '@mui/material';
import { TrainingPlan } from 'models/TrainingPlan';

import './DataGrid.styles.scss';

type DataGridProps = {
  className?: string;
  rows: GridRowsProp;
  columns: GridColumns;
  onImportSubmit?: (values: Record<string, unknown>) => void;
  onAddClicked?: (values: Record<string, unknown>) => void;
  onDeleteClicked?: (values: Record<string, unknown>) => void;
  onEditCellChange?: (editRowsModel: GridEditRowsModel) => void;
  onSelectionModelChange?: (selectedRows: string[]) => void;
  onImportButtonClicked?: () => boolean;
  selectedTrainingPlanIds?: boolean;
  loading?: boolean;
  setTrainingPlans?: React.Dispatch<React.SetStateAction<TrainingPlan[]>>;
};

type ModalRef = {
  open?: () => void;
  close?: () => void;
  current?: unknown;
};
const MyDataGrid: React.FC<DataGridProps> = (props) => {
  const [importModal, setModal] = useState<ModalRef>();
  const [editRowsModel, setEditRowsModel] = useState<GridEditRowsModel>();

  const {
    className,
    rows,
    columns,
    onEditCellChange = () => {},
    onImportSubmit = () => {},
    onAddClicked = () => {},
    onDeleteClicked = () => {},
    onSelectionModelChange = () => {},
    onImportButtonClicked = () => true,
    selectedTrainingPlanIds,
    loading,
    setTrainingPlans,
  } = props;

  const classes = classNames(className, 'my-data-grid');

  const onImportClicked = () => {
    const shouldOpen = onImportButtonClicked();
    shouldOpen && importModal?.open();
  };

  const onImportSubmitCb = async (values: Record<string, unknown>) => {
    onImportSubmit(values);
    importModal?.close();
  };
  const handleEditRowsModelChange = React.useCallback((model: GridEditRowsModel) => {
    setEditRowsModel(model);
  }, []);

  useEffect(() => {
    onEditCellChange(editRowsModel);
  }, [editRowsModel, onEditCellChange]);

  return (
    <div className={classes}>
      <Modal title="Import training plan" ref={setModal}>
        <Form
          onSubmit={onImportSubmitCb}
          render={({ handleSubmit }) => (
            <form onSubmit={handleSubmit} className="google-sheet-form">
              <div>
                <Field name="sheetId" render={({ input }) => <Input {...input} placeholder="GoogleSheet Id" />} />
                <Button type="submit">Submit</Button>
              </div>
            </form>
          )}
        />
      </Modal>

      <DataGrid
        loading={loading}
        className="my-data-grid__table"
        disableSelectionOnClick={true}
        onSelectionModelChange={(model) => {
          return onSelectionModelChange(model as string[]);
        }}
        rows={rows}
        columns={columns}
        editRowsModel={editRowsModel}
        // onCellFocusOut={onCellFocusOut}
        onEditRowsModelChange={handleEditRowsModelChange}
        checkboxSelection
        componentsProps={{
          toolbar: {
            onImportClicked,
            onAddClicked,
            onDeleteClicked,
            selectedTrainingPlanIds,
            setTrainingPlans,
          },
          loadingOverlay: {
            color: 'inherit',
          },
        }}
        components={{ Toolbar: CustomToolbar, ExportIcon, LoadingOverlay: LinearProgress }}
      />
    </div>
  );
};

type CustomToolbarProps = {
  onImportClicked: () => void;
  onAddClicked: () => void;
  onDeleteClicked: () => void;
  className?: string;
  selectedTrainingPlanIds?: boolean;
  setTrainingPlans?: React.Dispatch<React.SetStateAction<TrainingPlan[]>>;
};

const CustomToolbar: React.FC<CustomToolbarProps> = (props) => {
  const { onImportClicked, onDeleteClicked, className, selectedTrainingPlanIds, setTrainingPlans } = props;
  const [modal, modalRef] = useCallbackRef<ModalRef>();

  const classes = classNames(className, 'custom-toolbar');

  const handleAddTrainingPlan = React.useCallback(
    (trainingPlan: TrainingPlan) => {
      setTrainingPlans((prev) => [...prev, trainingPlan]);
      modal.close();
    },
    [modal, setTrainingPlans],
  );

  return (
    <GridToolbarContainer className={classes}>
      <GridToolbarExport className="ncoded-button ncoded-button--outline" />
      <Button className="import-button" onClick={() => onImportClicked()} variant="outline" ripple>
        IMPORT
      </Button>
      <Button onClick={() => modal?.open()} variant="outline" ripple>
        CREATE TRAINING
      </Button>
      <Button className="delete-button" onClick={() => onDeleteClicked()} variant="outline" ripple disabled={!selectedTrainingPlanIds}>
        DELETE
      </Button>
      <Modal ref={modalRef}>
        <TrainingPlanForm onCreate={handleAddTrainingPlan} />
      </Modal>
    </GridToolbarContainer>
  );
};
export default MyDataGrid;
