import classNames from 'classnames';
import React from 'react';
import './PageContentContainer.styles.scss';

type PageContentContainerProps = { className?: string };

const PageContentContainer: React.FC<PageContentContainerProps> = (props) => {
  const { children, className } = props;

  const classes = classNames(className, 'dpl-page-content-container');

  return <div className={classes}>{children}</div>;
};

export default PageContentContainer;
