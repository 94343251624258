import { useMemo } from 'react';
import { Activity } from 'api/users/activities';
import { TrainingPlan } from 'models/TrainingPlan';
import { constructChartData } from '../helpers/ChartData.helper';

type ChartDataProps = {
  trainingPlans: Partial<TrainingPlan & { activity: Partial<Activity> }>[];
};

// todo: make this general, pass all through props
export const useChartData = ({ trainingPlans }: ChartDataProps) => {
  const chartValues = useMemo(() => {
    return trainingPlans?.reduce(
      (acc, plan) => {
        acc.activityValues.push(+(plan.activity.distance / 1000).toFixed(2)); // path from props
        acc.planValues.push(+(plan.distance/1000).toFixed(2)); // path from props
        acc.categories.push(
          plan.startTime, // path from props
        );

        return acc;
      },
      { planValues: [], activityValues: [], categories: [] } as {
        planValues: number[];
        activityValues: number[];
        categories: string[];
      },
    );
  }, [trainingPlans]);

  const chartData = useMemo(() => {
    return constructChartData({
      activityValues: chartValues?.activityValues || [],
      planValues: chartValues?.planValues || [],
      title: 'Distance', // get from props
      categories: chartValues?.categories || [],
      formatter: (value: string) => `${value} km`, // get from props
    });
  }, [
    chartValues?.activityValues,
    chartValues?.planValues,
    chartValues?.categories,
  ]);

  return chartData;
};
