import { EnhancedRouteProps } from '../../routes/Enhanced/Enhanced.route';
import TrainingPlanPage from './pages/TrainingPlan';
import ComparsionChartPage from '../../../pages/ComparsionChart';
import StatisticsPage from '../../../pages/Statistics';
import ConnectGrid from 'components/ConnectGrid';
import redirect from 'router/modules/redirect';
export default [
  {
    path: '/trainings',
    component: TrainingPlanPage,
  },
  {
    path: '/connect',
    component: ConnectGrid,
  },
  {
    path: '/comparsion-chart',
    component: ComparsionChartPage,
  },
  {
    path: '/statistics',
    component: StatisticsPage,
  },
  {
    path: '/',
    component: redirect('/trainings', true),
  },
  {
    path: '*',
    component: redirect('/not-found', true),
  },
] as Array<EnhancedRouteProps>;
