import { useEffect } from 'react';
import { toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

export default function useToast() {
  useEffect(() => {
    toast.configure({
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }, []);
}
