interface CharDataOptions {
  planValues: number[];
  activityValues: number[];
  categories: string[];
  title: string;
  formatter: (value: string) => string;
}

export const constructChartData = ({
  planValues,
  activityValues,
  categories,
  title,
  formatter,
}: CharDataOptions) => ({
  series: [
    {
      name: 'Plan',
      data: planValues,
    },
    {
      name: 'Activity',
      data: activityValues,
    },
  ],
  options: {
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories,
    },
    yaxis: {
      title: {
        text: title,
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter,
      },
    },
  } as unknown as ApexCharts.ApexOptions,
});
