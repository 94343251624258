import React, { useCallback, useState } from 'react';
import api from 'api';
import { TrainingPlanReqBody } from 'api/users';
import classNames from 'classnames';
import { Button } from 'ncoded-component-library';
import Input from 'components/InputField';
import { Field, FieldRenderProps, Form } from 'react-final-form';
import { toast } from 'react-toastify';
import validators from 'validators';
import moment from 'moment';
import TextField from '@mui/material/TextField';
import utils from '../../utils';
import { TrainingPlan } from 'models/TrainingPlan';

import './TrainingPlanForm.styles.scss';

type TrainingPlanFormProps = {
  className?: string;
  onCreate?: (trainingPlan: TrainingPlan) => void;
};

type RenderDatePickerProps = {
  name: string;
  input: { value: any; onChange: (value: string) => void };
} & FieldRenderProps<number, HTMLElement> & {
    name: string;
    input: { value: any; onChange: (value: string) => void };
  };

const RenderDatePicker: React.FC<RenderDatePickerProps> = (props) => {
  const { input } = props;

  const [date, setDate] = useState<Date>(null);

  return (
    <TextField
      id="datetime-local"
      label="Start Time"
      type="datetime-local"
      defaultValue={moment(date).isValid() ? moment(date).format('DD.MM.yyyy. HH:mm') : moment().format('DD.MM.yyyy. HH:mm')}
      sx={{ width: 250 }}
      onChange={(ref) => {
        if (moment(ref.target.value).isValid()) {
          setDate(moment(ref.target.value).toDate());
          input.onChange(moment(ref.target.value).toISOString());
        } else {
          input.onChange(null);
        }
      }}
      InputLabelProps={{
        shrink: true,
      }}
    />
  );
};

const TrainingPlanForm: React.FC<TrainingPlanFormProps> = ({ className, onCreate }) => {
  const classes = classNames('dpl-training-plan-form', className);

  const onSubmit = useCallback(
    async (values: Record<string, unknown>) => {
      const { activeDuration: inputActiveDuration, totalDuration: inputTotalDuration, ...rest } = values;

      const activeDuration = utils.getTime(inputActiveDuration as string);
      const totalDuration = utils.getTime(inputTotalDuration as string);

      try {
        const trainingPlan = await api.users.createTrainingPlan({ activeDuration, totalDuration, ...rest } as TrainingPlanReqBody);
        onCreate(trainingPlan);
        toast.success('Created!');
      } catch (error) {
        toast.error((error as any).response?.data?.message || (error as Error).message);
      }
    },
    [onCreate],
  );

  const handleParseTime = useCallback((value: string) => {
    if (!value) return value;

    const onlyNums = value.replace(/[^\d]/g, '');

    if (onlyNums.length <= 2) return onlyNums;
    if (onlyNums.length <= 4) return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}`;
    return `${onlyNums.slice(0, 2)}:${onlyNums.slice(2, 4)}:${onlyNums.slice(4, 6)}`;
  }, []);

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit} className={classes}>
          <h2>Create training</h2>
          <div className="pairInput">
            <Field
              label="Elevation"
              name="elevation"
              component={Input}
              type="number"
              placeholder="e.g. 10"
              suffixNode={<span>m</span>}
              validate={validators.composeValidators(validators.required, validators.minValue(0))}
            />
            <Field
              label="Distance"
              name="distance"
              component={Input}
              type="number"
              placeholder="e.g. 5000"
              suffixNode={<span>m</span>}
              validate={validators.composeValidators(validators.required, validators.minValue(0))}
            />
          </div>
          <div className="pairInput">
            <Field
              label="Active Duration"
              name="activeDuration"
              component={Input}
              parse={handleParseTime}
              placeholder="hh:mm:ss"
              type="text"
              validate={validators.composeValidators(validators.required, validators.time)}
            />
            <Field
              label="Total Duration"
              name="totalDuration"
              component={Input}
              parse={handleParseTime}
              type="text"
              validate={validators.composeValidators(validators.required, validators.time)}
              placeholder="hh:mm:ss"
            />
          </div>
          <div className="tripleInput">
            <Field
              label="Pulse"
              name="pulse"
              component={Input}
              type="number"
              validate={validators.composeValidators(validators.required, validators.minValue(0))}
              placeholder="e.g. 130"
              suffixNode={<span>bpm</span>}
            />
            <Field
              label="Cadence"
              name="cadence"
              component={Input}
              type="number"
              validate={validators.composeValidators(validators.required, validators.minValue(0))}
              placeholder="e.g. 10"
              suffixNode={<span>spm</span>}
            />
            <Field label="Start Time" name="startTime" component={RenderDatePicker} validate={validators.composeValidators(validators.required)} />
          </div>
          <Button type="submit" disabled={invalid}>
            Submit
          </Button>
        </form>
      )}
    />
  );
};

export default TrainingPlanForm;
